import React, { useState } from "react";
import { IconContext } from 'react-icons';
import {  IoShareOutline, IoCloseOutline } from "react-icons/io5";
import { RiFullscreenLine, RiTwitterLine, RiFacebookLine } from "react-icons/ri";
import { Tween } from "react-gsap";

export default function Nav() {

  const [ showDefaultShare, setShowDefaultShare ] = useState(false);

  function handleShareClick(event) {
    console.log('share click');
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      
        function getCurrentURL() {
        return window.location.href;
        }
        const shareLink = getCurrentURL();

        const shareData = {
                title: "PLAYrePLAY",
                text: "PLAYrePLAY celebrates the idea of play and encourages re-plays… many replays.",
                url: shareLink,
        }
        navigator.share(shareData);
        console.log('share enabled');

    }
    else{
      console.log('share not enabled');
      setShowDefaultShare( !showDefaultShare );
    }  
  }

  return (
      <nav>
      <div className='w-full flex justify-center bg-grey uppercase text-sm mt-2 lg:mt-6 py-2 gap-2'>
        <button className='underline uppercase' id="about-link">About playreplay</button> |  
        <div className='flex'>
          <button id="share" className="share uppercase" data-share-title="PLAYrePLAY" data-share-url="https://playreplay.ca" onClick={handleShareClick}>Share</button>
        
            {showDefaultShare &&
                <Tween
                from={{opacity:0}}
                to={{opacity:1}}
              >
              <div className="flex">
                    <a id="fb" rel="noopener noreferrer" target="_blank" className="ml-2 social fb"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://playreplay.ca"
                  data-size="large" data-share-title="PLAYrePLAY" data-share-url="https://playreplay.ca"> 
                    <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                    <RiFacebookLine />
                </IconContext.Provider>
                </a>
                <a id="tw" rel="noopener noreferrer" target="_blank" className=" ml-2 social tw"
                  href="https://twitter.com/intent/tweet?text=https://playreplay.ca"
                  data-size="large" data-share-title="PLAYrePLAY" data-share-url="https://playreplay.ca">
                    <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                    <RiTwitterLine />
                    </IconContext.Provider> 
                  </a>
                </div>
              </Tween>
            }
        </div>
        
      </div>
    </nav>
  );
}