
import React, { useEffect, useState }  from "react";
import { IconContext } from 'react-icons';
import { AiOutlineRight } from 'react-icons/ai';
import {  IoCloseOutline } from "react-icons/io5";


function Credits({settings,nav}) {

    const closeTrigger = document.querySelector('#collective-credits');

    return (
        <>
            
                <div className="fullscreen collective" id="collective-credits">
                    <div className="w-full text-center px-8">
               
                            <h1 className='pt-16 text-2xl'>{settings.title}</h1>
                            {settings.excerpt.split('\n').map(p=><p className="sm:mx-8" dangerouslySetInnerHTML={{__html:p}}></p>)}

                            <h2>{settings.title2}</h2>
                            {settings.excerpt2.split('\n').map(p=><p className="sm:mx-8" dangerouslySetInnerHTML={{__html:p}}></p>)}
                            
                            <h2>{settings.title3}</h2>
                            <div className='flex flex-col justify-center lg:grid lg:grid-cols-3 gap-8 w-full mx-auto max-w-sm lg:max-w-full lg:px-4'>
                                {settings.credits.map((credit,index) => {
                                    return (
                                        <div className="flex flex-col" key={index} data-index={index}> 
                                        <h3>{credit.title}</h3>
                                        {credit.list && credit.list.split('\n').map((li,i)=><li dangerouslySetInnerHTML={{__html:li}} key={`l1-${i}`}></li>)}
                                        <h3>{credit.title2}</h3>
                                        {credit.list2 && credit.list2.split('\n').map((li,i)=><li dangerouslySetInnerHTML={{__html:li}} key={`l2-${i}`}></li>)}
                                        </div>
                                    );
                                })}
                            </div>
                            <h2>{settings.title4}</h2>
                            <p className="sm:mx-8">{settings.excerpt4}</p>
                            <div className='flex justify-center mt-4 mb-8 gap-12'>
                                {settings.sponsors1.map((sponsor,index) => {
                                    return (
                                        <div className="flex justify-center text-left items-center gap-4" key={index} data-index={index}> 
                                            <img src={sponsor.logo} alt={sponsor.name} className="object-scale-down object-center" />
                                            {!sponsor.hideName && <span dangerouslySetInnerHTML={{__html:sponsor.name.replace('\n','<br/>')}}></span>}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className='flex justify-center gap-12'>
                                {settings.sponsors2.map((sponsor,index) => {
                                    return (
                                        <div className="flex justify-center text-left items-center gap-4" key={index} data-index={index}> 
                                            <img src={sponsor.logo} alt={sponsor.name} className="object-scale-down object-center" />
                                            {!sponsor.hideName && <span dangerouslySetInnerHTML={{__html:sponsor.name.replace('\n','<br/>')}}></span>}
                                        </div>
                                    );
                                })}
                            </div>
                            <h2 className="">{settings.title5}</h2>
                            {settings.acknowledgement.split('\n').map(p=><p className="sm:mx-8" dangerouslySetInnerHTML={{__html:p}}></p>)}

                    </div>
                </div>
        </>
        
    );
}

export default Credits;